<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>Indent List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="asset_list_table"
             class="table table-bordered"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-width="100" data-field="code" >Code</th>
          <th data-field="date" >Date</th>
          <th data-field="purpose" >Purpose</th>
          <th data-field="init_ibr" >Initiated Branch</th>
          <th data-field="bene_ibr" >Beneficiary Branch</th>
          <th data-field="item.name" >Item</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-cancel-square" ></i>Delete</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="indent-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="60%" :height="430" @before-open="beforeOpen" @before-close="beforeClose" >
      <IndentForm v-bind:mygroup="indent" ></IndentForm>
    </vmodal>

  </div>

</template>

<script>
  import IndentForm from '@/components/v1/indent/IndentForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'

  export default {
    name: 'IndentView',
    components: {
      IndentForm,
      FabButton
    },
    data () {
      return {
        mytable: {},
        indent: JSON.parse('{"Id":"","Code":0,"Date":"0001-01-01","InitiatedBranch":{"id":"","code":0,"name":""},"BeneficiaryBranch":{"id":"","code":0,"name":""},"Item":{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":1,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0,"system_object":false},"Purpose":0,"Qty":0,"List":[]}')
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#asset_list_table');
      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      $('#indent-table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument(id);
          }else if ($(e.target).text() === 'Delete') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#indent-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide();
      });

      self.loadData();
    },
    methods: {
      closeModal () {
        this.$modal.hide('indent-window');
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.loadData();
      },
      showModal () {
        this.group= JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":1,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false}');
        this.$modal.show('indent-window')
      },

      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.group = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/it/indent/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.group = data.data;
            self.$modal.show('indent-window');
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.group.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.group)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/it/indent/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('indent-window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
              // swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };
        self.$data.mytable.bootstrapTable('load',[]);


        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/it/indent/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      save () {
        alert('Welcome')
      }
    }

  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
